import React, { Component } from 'react';
import { Label, Input, Card, Table } from 'reactstrap';
import EarningsCard from './EarningsCard.js'
import Terms from './Terms.js';

class ResultRow extends Component {
  render() {
    return (
      <tr style={{display: !this.props.display ? 'none' : ''}} className={this.props.rowClass}>
        <td>{this.props.title}</td>
        <td>&pound;{window.formatCurrency(this.props.value)}</td>
        <td>&pound;{window.formatCurrency(this.props.value/12)}</td>
        <td className="d-none d-sm-block">&pound;{window.formatCurrency(this.props.value/52)}</td>
      </tr>
    );
  }
}

ResultRow.defaultProps = {
  title: '',
  value: 0,
  rowClass: '',
  display: true,
};

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showFullCalculation: true,
    };
  }

  render() {

    if (Object.keys(this.props.data).length === 0 && this.props.data.constructor === Object) {
      return <span />
    }

    let studentLoan = null;
    if (this.props.data.studentLoan) {
      studentLoan = <ResultRow title="Student Loan Repayment" value={this.props.data.studentLoanRepayment} display={this.state.showFullCalculation} />;
    }

    return (
      <div>
        <EarningsCard income={this.props.data.netIncome} />
        <br />
        <Card className="shadow-sm">
          <Table responsive striped hover size="sm">
            <thead>
              <tr>
                <th />
                <th>Year</th>
                <th>Month</th>
                <th className="d-none d-sm-block">Week</th>
              </tr>
            </thead>
            <tbody>
              <ResultRow
                title="Earnings"
                value={this.props.data.annualIncome}
              />
              <ResultRow
                title="Tax Free allowance"
                value={this.props.data.taxFreeAllowance}
              />
              <ResultRow
                title="Taxable Earnings"
                value={this.props.data.taxableEarnings}
              />
              <ResultRow
                title="Basic Rate Tax Due (20%)"
                value={this.props.data.taxDue.basic}
                display={this.state.showFullCalculation}
              />
              <ResultRow
                title="Higher Rate Tax Due (40%)"
                value={this.props.data.taxDue.higher}
                display={this.state.showFullCalculation}
                rowClass={this.props.data.taxDue.higher > 0 ? 'danger' : ''}
              />
              <ResultRow
                title="Top Rate Tax Due (45%)"
                value={this.props.data.taxDue.top}
                display={this.state.showFullCalculation}
              />
              <ResultRow
                title="Pension Contributions"
                value={this.props.data.pensionValue}
                display={this.state.showFullCalculation}
              />
              <ResultRow
                title="Pension Contributions (HMRC)"
                value={this.props.data.pensionValue * 0.25}
                display={this.state.showFullCalculation}
              />
              <ResultRow
                title="National Insurance"
                value={this.props.data.nationalInsuranceDue}
                display={this.state.showFullCalculation}
              />
              {studentLoan}
              <ResultRow
                title="Total Deductions"
                value={this.props.data.totalDeductions}
                rowClass="info"
              />
              <ResultRow
                title="Total After Deductions"
                value={this.props.data.netIncome}
                rowClass="primary"
              />
            </tbody>
          </Table>
        </Card>
        <div className="show__full__calculation">
          <div className="form-check">
            <Label check>
              <Input
                type="checkbox"
                value={this.state.showFullCalculation}
                checked={this.state.showFullCalculation}
                onChange={event =>
                  this.setState({
                    showFullCalculation: !this.state.showFullCalculation
                  })
                }
              />{" "}
              <small className="text-muted">Show Full Calculation</small>
            </Label>
          </div>
        </div>
        <Terms />
      </div>
    );
  }
}

Results.defaultProps = {
  data: {
    income: 0,
    netIncome: 0,
    taxDue: {
      basic: 0,
      higher: 0,
      top: 0,
      total: 0,
    }
  }
};

export default Results;
