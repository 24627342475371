import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form';
import InitialData from '../Data/InitialData';
import TaxYear from './TaxYear.js'
import AdvancedOptionsContainer from './AdvancedOptionsContainer.js';
import EarningsInput from './EarningsInput.js';

class Form extends Component {
  changeTaxYear(props, value) {
    props.input.onChange(value);
    this.changeTaxCode(value);
  }
  changeTaxCode(value) {
    let marriageAllowance = this.props.marriageAllowance;
    if (value === null) {
      value = this.props.taxYear;
      marriageAllowance = !marriageAllowance;
    }

    let taxCode = InitialData.defaultTaxCodes[value].replace(/\D/g, '');
    if (marriageAllowance) {
      if (taxCode.length === 0) {
        taxCode = 0;
      }
      taxCode = Math.ceil(parseInt(taxCode, 10) * 1.1);
    }

    this.props.change('taxCode', taxCode + 'L');
  }
  render() {
    return (
      <div>
        <Field name="taxYear" component={(props) => {
          return <TaxYear value={props.input.value} onChange={(value) => this.changeTaxYear(props, value)} />
        }} />
        <EarningsInput />
        <AdvancedOptionsContainer changeTaxCode={this.changeTaxCode.bind(this)} />
      </div>
    )
  }
}

Form = reduxForm({
  form: 'ukTaxCalculatorMainForm',
  initialValues: InitialData
})(Form);

const selector = formValueSelector('ukTaxCalculatorMainForm')
Form = connect(
  state => {
    const { taxYear, taxCode, marriageAllowance, pension, salarySacrifice } = selector(state, 'taxYear', 'taxCode', 'marriageAllowance', 'pension', 'salarySacrifice')
    return {
      salarySacrifice,
      pension,
      taxYear,
      taxCode,
      marriageAllowance,
    }
  }
)(Form)

export default Form;