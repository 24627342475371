import React, { Component } from 'react';
import { Label, InputGroup, InputGroupAddon, Input, FormGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import { Field } from 'redux-form';


const Timeframes = [
  {
    value: 1,
    text: 'Per Year'
  },
  {
    value: 12,
    text: 'Per Month'
  },
  {
    value: 26,
    text: 'Per Fortnight'
  },
  {
    value: 52,
    text: 'Per Week'
  },
  {
    value: 260,
    text: 'Per Day'
  },
  {
    value: 1950,
    text: 'Per Hour (37.5 Hour Week)'
  },
];

const incomeInput = (props) => (
  <Input type="number" step={100} style={{ minWidth: '20%' }} value={props.input.value} onChange={(event) => { props.input.onChange(event.target.value) }} />
);

export default class EarningsInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDropdown: false,
    };
  }
  render() {

    const timeframeFields = Timeframes.map(function (option, index) {
      return (
        <Field key={index} name="time" component={(props) => {
          return <DropdownItem className={props.input.value === option.value ? 'active' : ''}
            onClick={props.input.onChange.bind(this, props.input.value === option.value ? props.input.value : option.value)}>
            {option.text}
          </DropdownItem>;
        }} />
      )
    }.bind(this));


    return (
      <FormGroup>
        <Label>
          <Badge color="primary" pill>2</Badge> My income is
        </Label>
        <InputGroup size="lg" className="shadow-sm">
          <InputGroupAddon addonType="prepend">&pound;</InputGroupAddon>
          <Field name="income" component={incomeInput} />
          <Field name="time" component={(props) => {
            return (
              <ButtonDropdown isOpen={this.state.toggleDropdown} toggle={() => { this.setState({ toggleDropdown: !this.state.toggleDropdown }) }}>
                <DropdownToggle color={this.state.toggleDropdown || props.input.value ? 'primary' : 'secondary'} caret>
                  {Timeframes.map(function(timeframe, index) {
                    return timeframe.value === props.input.value ? timeframe.text : null;
                  })}
                </DropdownToggle>
                <DropdownMenu>{timeframeFields}</DropdownMenu>
              </ButtonDropdown>
            )
          }} />
        </InputGroup>
      </FormGroup>
    );


  }
}