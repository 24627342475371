import React from 'react';
import { Input } from 'reactstrap';
import { Field } from 'redux-form';

const input = (props) => (
	<Input className="shadow-sm" type={props.type ? props.type : 'text'} style={props.style} value={props.input.value} onChange={(event) => props.input.onChange(event.target.value)} />
);

export default ({ name, type, style }) => (
    <Field name={name} component={input} props={{
    	type: type,
    	style: style,
    }} />
);