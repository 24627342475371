import React, { Component } from "react";
import { Card, CardTitle, CardHeader, CardBody } from "reactstrap";
import SingleOption from "./SingleOption.js";
import "./../App.css";

class EarningsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeframe: "Month"
    };
  }
  updateValue(value) {
    this.setState({
      timeframe: value
    });
  }
  render() {
    const divide = {
      Day: 5 * 52,
      Week: 52,
      Month: 12,
      Year: 1
    };

    return (
      <Card className="text-center shadow-sm">
        <CardHeader>Your earnings result</CardHeader>
        <CardBody>
          <CardTitle className="earnings__value">
            &pound;
            {window.formatCurrency(this.props.income / divide[this.state.timeframe])}
          </CardTitle>
          <SingleOption
            value={this.state.timeframe}
            onChange={this.updateValue.bind(this)}
            options={["Day", "Week", "Month", "Year"]}
          />
        </CardBody>
      </Card>
    );
  }
}

EarningsCard.defaultProps = {
  income: 0
};

export default EarningsCard;
