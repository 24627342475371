import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import FontAwesome from 'react-fontawesome'

class SingleOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    })
  }

  change(value, event) {
    event.preventDefault();
    this.props.onChange(value, this.props.stateField);
  }

  render() {
    let options = null;
    if (this.props.options) {
      options = this.props.options.map(function(value, index) {
        return (
        <Button key={index} color={value === this.state.value ? 'primary' : 'secondary'} id={value} onClick={this.change.bind(this, value)}>
          { value === this.state.value && <FontAwesome name="check" /> } {value}
        </Button>
        )
      }.bind(this));
    }
    return (
      <ButtonGroup>{options}</ButtonGroup>
    );
  }
}

SingleOption.defaultProps = {
  options: [],
  stateField: '',
};

export default SingleOption;
