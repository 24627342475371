import React, { Component } from 'react'
import { values as valuesDecorator } from 'redux-form';
import Results from './Results.js'

export default class ResultsContainer extends Component {
  calculate(values) {
    const nationalInsuranceRates = {
        '2016/17': {
          primaryThreshold: 12 * 672,
          upperEarningsLimit: 12 * 3583,
          rates: {
            betweenPrimaryThresholdAndUpperEarningsLimit: 0.12,
            aboveUpperEarningsLimit: 0.02,
          }
        },
        '2017/18': {
          primaryThreshold: 12 * 680,
          upperEarningsLimit: 12 * 3750,
          rates: {
            betweenPrimaryThresholdAndUpperEarningsLimit: 0.12,
            aboveUpperEarningsLimit: 0.02,
          }
        },
        '2018/19': {
          primaryThreshold: 8424,
          upperEarningsLimit: 46350,
          rates: {
            betweenPrimaryThresholdAndUpperEarningsLimit: 0.12,
            aboveUpperEarningsLimit: 0.02,
          }
        },
        '2019/20': {
          primaryThreshold: 8632,
          upperEarningsLimit: 50000,
          rates: {
            betweenPrimaryThresholdAndUpperEarningsLimit: 0.12,
            aboveUpperEarningsLimit: 0.02,
          }
        },
        '2020/21': {
          primaryThreshold: 9516,
          upperEarningsLimit: 50024,
          rates: {
            betweenPrimaryThresholdAndUpperEarningsLimit: 0.12,
            aboveUpperEarningsLimit: 0.02,
          }
        },
        '2021/22': {
          primaryThreshold: 9568,
          upperEarningsLimit: 50284,
          rates: {
            betweenPrimaryThresholdAndUpperEarningsLimit: 0.12,
            aboveUpperEarningsLimit: 0.02,
          }
        },
        '2022/23': {
          primaryThreshold: 9880,
          upperEarningsLimit: 50270,
          rates: {
            betweenPrimaryThresholdAndUpperEarningsLimit: 0.1325,
            aboveUpperEarningsLimit: 0.0325,
          }
        },
    }

    let data = values;
    data.incomeCalc = data.income.length === 0 ? 0 : data.income;

    if (data.income > 100000000) {
      data.income = 100000000;
    }

    data.annualIncome = parseFloat(data.incomeCalc) * parseFloat(data.time);

    let taxCode = data.taxCode.replace(/\D/g, '');
    if (taxCode.length === 0) {
      taxCode = 0;
    }

    data.taxFreeAllowance = parseFloat(taxCode) * 10;

    if (data.incomeCalc > 100000) {
      let taxFreeAllowanceReduction = (data.incomeCalc - 100000) / 2;
      data.taxFreeAllowance -= taxFreeAllowanceReduction;
      if (data.taxFreeAllowance < 0) {
        data.taxFreeAllowance = 0;
      }
    }

    if (data.blind) {
      data.taxFreeAllowance += data.blindAllowance;
    }

    if (data.allowanceOrDeduction) {
      data.taxFreeAllowance += parseFloat(data.allowanceOrDeduction);
      if (data.taxFreeAllowance < 0) {
        data.taxFreeAllowance = 0;
      }
    }

    const taxBands = {
        '2014/15': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 31865,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 31865,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2015/16': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 31785,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 31785,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2016/17': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 32000,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 32000,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2017/18': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 33500,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 33500,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2018/19': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 34500,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 34500,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2019/20': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 37500,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 37500,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2020/21': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 37500,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 37500,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2021/22': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 37700,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 37700,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
        '2022/23': {
          none: {
            minimum: 0,
            limit: data.taxFreeAllowance,
            rate: 0,
          },
          basic: {
            minimum: data.taxFreeAllowance,
            limit: data.taxFreeAllowance + 37700,
            rate: 0.2,
          },
          higher: {
            minimum: data.taxFreeAllowance + 37700,
            limit: 150000,
            rate: 0.4,
          },
          top: {
            minimum: 150000,
            limit: 9999999999999999999,
            rate: 0.45,
          },
        },
    }

    data.taxableEarnings = 0;
    if ((data.annualIncome - data.taxFreeAllowance) > 0) {
      data.taxableEarnings = data.annualIncome - data.taxFreeAllowance;
    }

    data.pensionValue = data.pension;
    if (data.pension.length > 0 || data.salarySacrifice.length > 0) {
      data.pensionValue = data.pension || data.salarySacrifice;
      if (data.salarySacrifice.length > 0 || (data.pension.substring(data.pension.length-1) === "%" && !isNaN(data.pension.substring(0, data.pension.length - 1)))) {
        if (data.salarySacrifice.length > 0) {
          data.pensionValue = data.annualIncome * (data.salarySacrifice / 100);
        } else {
          data.pensionValue = data.annualIncome * (data.pension.substring(0, data.pension.length - 1) / 100);
        }
      } else if(isNaN(data.pensionValue)) {
        data.pensionValue = 0;
        data.pension = '';
      } else {
        data.pensionValue = parseFloat(data.pension);
      }
      if (data.salarySacrifice.length > 0) {
        data.taxableEarnings -= data.pensionValue;
      } 
      data.annualIncome -= data.pensionValue;
      data.pensionValue = 0;
      data.pension = '';
    } else {
      data.pensionValue = 0;
    }

    if (data.pensionValue > data.annualIncome) {
      data.pensionValue = data.annualIncome;
    }



    let currentTaxBand = taxBands[data.taxYear];
    for (var band in currentTaxBand) {
        if (!currentTaxBand.hasOwnProperty(band)) {
          continue;
        }
        if (data.annualIncome > currentTaxBand[band].minimum) {
          data.taxBand = band;
        }
    }



    data.studentLoan = data.studentLoanPlan.length > 0;
    data.studentLoanRepayment = 0;
    if (data.studentLoan) {
      let studentLoanLimit = data.studentLoanRepayments[data.studentLoanPlan].limit;
      if (data.annualIncome > studentLoanLimit) {
        data.studentLoanRepayment = Math.round((data.annualIncome - studentLoanLimit) * data.studentLoanRepayments[data.studentLoanPlan].value);
      }
    }
    data.taxableEarnings -= data.studentLoanRepayment;

    if (data.taxableEarnings < 0) {
      data.taxableEarnings = 0;
    }

    data.taxDue = {};
    data.taxDue.total = 0;
    data.nationalInsuranceDue = 0;

    let currentNationalInsuranceRates = nationalInsuranceRates[data.taxYear];
    if (data.annualIncome > currentNationalInsuranceRates.primaryThreshold && !data.noNi) {
      if ((data.annualIncome - currentNationalInsuranceRates.upperEarningsLimit) < 0) {
        data.nationalInsuranceDue = (data.annualIncome - currentNationalInsuranceRates.primaryThreshold) * currentNationalInsuranceRates.rates.betweenPrimaryThresholdAndUpperEarningsLimit;
      } else {
        data.nationalInsuranceDue = (currentNationalInsuranceRates.upperEarningsLimit - currentNationalInsuranceRates.primaryThreshold) * currentNationalInsuranceRates.rates.betweenPrimaryThresholdAndUpperEarningsLimit;
        data.nationalInsuranceDue += ((data.annualIncome - currentNationalInsuranceRates.upperEarningsLimit) * currentNationalInsuranceRates.rates.aboveUpperEarningsLimit);
      }
    }

    data.nationalInsuranceDue = Math.round(data.nationalInsuranceDue * 100) / 100;

    currentTaxBand = taxBands[data.taxYear];
    let earnings = data.taxableEarnings + data.taxFreeAllowance;
    let cumulativeMinimum = 0;

    for (band in currentTaxBand) {
        if (!currentTaxBand.hasOwnProperty(band)) {
          continue;
        }

        let minimum = currentTaxBand[band].minimum;
        let limit = currentTaxBand[band].limit;
        let rate = currentTaxBand[band].rate;
        let due = 0;
        data.taxDue[band] = due;

        if (earnings < 0) {
          continue;
        }

        if (rate > 0) {

          let tempEarnings = earnings;
          if (earnings - limit > 0) {
            tempEarnings = (limit - minimum) + cumulativeMinimum;
          }

          if (band !== 'none' && tempEarnings > (limit - data.taxFreeAllowance)) {
            tempEarnings = limit - data.taxFreeAllowance;
          }
          due = tempEarnings * rate;

          if (due < 0) {
            due = 0;
          }
        }

        data.taxDue[band] = due;
        data.taxDue.total = due + data.taxDue.total;
        cumulativeMinimum += minimum;
        earnings = data.taxableEarnings + data.taxFreeAllowance - limit;
    }

    data.totalDeductions = data.taxDue.total + data.nationalInsuranceDue + data.pensionValue + data.studentLoanRepayment;
    if (data.totalDeductions > data.annualIncome) {
      data.totalDeductions = data.annualIncome;
    }
    data.netIncome = data.annualIncome - data.totalDeductions;
    return data;
  }
  render() {
    const { form } = this.props;
    const decorator = valuesDecorator({ form })
    const component = ({ values }) => {
      return <Results data={this.calculate(values)} />
    }
    const Decorated = decorator(component);
    return <Decorated />
  }
}