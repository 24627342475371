import React, { Component } from 'react';
import { Row, Col, FormText, Label, Input, FormGroup, UncontrolledPopover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import { Field } from 'redux-form';
import BootstrapTextField from './BootstrapTextField.js';
import AdvancedMode from './AdvancedMode.js';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import StudentLoans from './StudentLoans.js';

export default class AdvancedOptionsContainer extends Component {
  showAdvanced(props, value) {
    props.input.onChange(value);
    let advancedOptions = $(ReactDOM.findDOMNode(this.refs.advancedOptions));
    return value ? advancedOptions.slideDown(500) : advancedOptions.slideUp(500);
  }
  marriageAllowance(props, value) {
    props.input.onChange(value);
    this.props.changeTaxCode(null);
  }
  render() {
    return (
      <div>
        <div className="text-center">
          <Field name="showAdvanced" component={(props) => {
            return <AdvancedMode value={props.input.value} onChange={(value) => this.showAdvanced(props, value)} />  //
          }} />
        </div>
        <div ref="advancedOptions" style={{display: 'none'}}>
          <hr />
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Tax code <small className="muted" style={{ color: '#818a91'}}>Optional</small></Label>
                <BootstrapTextField name="taxCode" />
                <FormText color="muted">
                  <div className="form-check">
                    <Label check>
                      <Field name="marriageAllowance" component={(props) => {
                        return <Input type="checkbox" value={props.input.value} checked={props.input.value} onChange={(event) => this.marriageAllowance(props, !props.input.value)} />
                      }} />{' '}
                      Add Marriage Allowance? <Button size="sm" color="link" id="marriage--allowance--popover">What's this?</Button>
                    </Label>
                  </div>
                  <UncontrolledPopover trigger="click" target="marriage--allowance--popover">
                    <PopoverHeader>Marriage Allowance - How it works</PopoverHeader>
                    <PopoverBody>
                      <p>Marriage Allowance lets you transfer &pound;1,190 of your Personal Allowance to your husband, wife or civil partner - if they earn more than you.</p>
                      <p>This reduces their tax by up to £238 in the tax year (6 April to 5 April the next year).</p>
                      <p>To benefit as a couple, you (as the lower earner) must normally have an income below your Personal Allowance - this is usually &pound;11,850.</p>
                      <p>More information can be found on the government website <a href="https://www.gov.uk/marriage-allowance/how-it-works" rel="noopener noreferrer" target="_blank">here</a>.</p>
                    </PopoverBody>
                  </UncontrolledPopover>
                </FormText>
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Pension Contribution</Label>
                <BootstrapTextField name="pension" />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <Label>Student Loans</Label>
              <StudentLoans />
              <FormText color="muted">Select if you are repaying a student loan</FormText>              
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Salary Sacrifice (%)</Label>
                <BootstrapTextField name="salarySacrifice" type="number" />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}