import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import ReactDOM from 'react-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import $ from 'jquery';
import FontAwesome from 'react-fontawesome'
import ReactGA from 'react-ga';
import Form from './Components/Form.js';
import ResultsContainer from './Components/ResultsContainer.js';
import Head from './Components/Head.js';
import Navbar from './Components/Navbar.js';


if (window.location.host.indexOf('localhost') === -1) {
  ReactGA.initialize('UA-34026954-5');
  ReactGA.pageview('/');
  ReactGA.event({
      category: 'App',
      action: 'Started',
  });
}

window.formatCurrency = function(x, decimalPlaces) {
  return parseFloat(x).toFixed(decimalPlaces !== undefined ? decimalPlaces : 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class App extends Component {
  submit(event) {
    event.preventDefault();
    $(ReactDOM.findDOMNode(this.refs.calculateButton)).slideUp(500);
    $(ReactDOM.findDOMNode(this.refs.results)).slideDown(500);
  }
  render() {
    return (
      <span>
        <Head />
        <Navbar />
        <Container className="App">
          <Row>
            <Col xs={12} sm={{ offset: 2, size: 8 }}>
              <div className="form__container">
                <div ref="form">
                  <Form />
                  <br />
                  <div ref="calculateButton">
                    <Button block outline color="primary" size="lg" onClick={this.submit.bind(this)}>
                      <FontAwesome name="calculator" /> Calculate my Income
                    </Button>
                  </div>
                </div>
                <div className="results" style={{ display: "none" }} ref="results">
                  <ResultsContainer form="ukTaxCalculatorMainForm" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <footer>
                <p className="copyright">
                  Copyright &copy; 2016-{new Date().getFullYear()}
                </p>
              </footer>
            </Col>
          </Row>
        </Container>
      </span>
    );
  }
}

export default App;
