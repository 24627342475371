import React, { Component } from 'react';
import { Button, ButtonGroup, Label, FormGroup, Badge } from 'reactstrap';
import FontAwesome from 'react-fontawesome'

export default class TaxYear extends Component {
  render() {
    let years = ['2019/20', '2020/21', '2021/22', '2022/23'];
    years = years.map(function(value, index) {
      return (
        <Button className="w-100" key={index} color={value === this.props.value ? 'primary' : 'secondary'} id={value} onClick={this.props.onChange.bind(this, value)}>
          {value === this.props.value && <FontAwesome className="d-none d-sm-inline" name="check" /> } {value}
        </Button>
      )
    }.bind(this));
    return (
      <FormGroup>
        <Label for="taxYear">
          <Badge color="primary" pill>1</Badge> Select tax year
        </Label>
        <br/>
        <ButtonGroup id="taxYear" className="d-flex">
          {years}
          {/* <Button disabled={true} className="w-100 d-none d-sm-block">2022/23</Button> */}
        </ButtonGroup>
      </FormGroup>
    );
  }
}
