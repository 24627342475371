import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Field } from 'redux-form';

const StudentLoanPlans = [
  {
    value: 'Plan1',
    text: 'Plan 1',
  },
  {
    value: 'Plan2',
    text: 'Plan 2',
  },
];

export default class StudentLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDropdown: false,
    };
  }
  render() {

    const plans = StudentLoanPlans.map(function(option, index) {
      return (
        <Field key={index} name="studentLoanPlan" component={(props) => {
          return <DropdownItem className={props.input.value === option.value ? 'active' : ''}
            onClick={props.input.onChange.bind(this, props.input.value === option.value ? '' : option.value)}>
            {option.text}
          </DropdownItem>;
        }} />
      )
    }.bind(this));

    return (
      <Field name="studentLoanPlan" component={(props) => {
        return (
        <ButtonDropdown className="w-100" isOpen={this.state.toggleDropdown} toggle={() => {this.setState({toggleDropdown: !this.state.toggleDropdown})}}>
            <DropdownToggle className="w-100"  color={this.state.toggleDropdown || props.input.value ? 'primary' : 'secondary'} caret>
            Student Loan
          </DropdownToggle>
          <DropdownMenu>{plans}</DropdownMenu>
        </ButtonDropdown>
        )
      }} />
    );
  }
}