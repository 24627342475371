import React from 'react';
import { Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome'

export default ({ value, onChange }) => {
    return (
      <Button className="advanced__mode" color={value ? 'primary' : 'secondary'} size="sm" onClick={() => onChange(!value)}>
        <FontAwesome name={value ? 'eye-slash' : 'eye'} /> {value ? 'Hide' : 'show'} Advanced Options
      </Button>
    );
}