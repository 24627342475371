import React from "react";
import {
  Navbar,
  NavbarBrand,
  // NavbarToggler,
  // Collapse,
  // Nav,
  // NavItem,
  // NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Container
} from "reactstrap";

export default () => {
  return (
    <Navbar light expand="md" className="shadow-sm">
      <NavbarBrand href="/">
        <img
          src="logo.png"
          className="logo"
          alt="UK ad-free Tax Calculator"
        />
        <h1 className="lead">
          The <b>simple</b> UK income tax calculator.
        </h1>
      </NavbarBrand>
    </Navbar>
  );
};
