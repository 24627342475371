var currentDate = new Date();

let defaultTaxYear = (currentDate.getFullYear() - 1) + '/' + ((currentDate.getFullYear()) - 2000);
if (currentDate.getMonth() >= 4 && currentDate.getDate() >= 6) {
  defaultTaxYear = currentDate.getFullYear() + '/' + ((currentDate.getFullYear() + 1) - 2000);
}

const defaultTaxCodes = {
  '2017/18': '1150L',
  '2018/19': '1185L',
  '2019/20': '1250L',
  '2020/21': '1250L',
  '2021/22': '1257L',
  '2022/23': '1257L',
};

export default {
  showAdvanced: false,
  taxDue: {
    basic: 0,
    higher: 0,
    top: 0,
    total: 0,
  },
  taxBand: 'none',
  taxYear: defaultTaxYear,
  taxCode: defaultTaxCodes[defaultTaxYear],
  income: '',
  taxableEarnings: 0,
  incomeState: '',
  time: 1,
  pension: 0,
  defaultTaxCodes: defaultTaxCodes,
  blind: false,
  blindAllowance: 2320,
  noNi: false,
  marriageAllowance: false,
  salarySacrifice: false,
  studentLoan: false,
  studentLoanRepayment: 0,
  studentLoanPlan: '',
  studentLoanRepayments: {
    Plan1: {
      limit: 19390,
      value: 0.09,
    },
    Plan2: {
      limit: 26575,
      value: 0.09,
    },
  },
  allowanceOrDeduction: 0,
};